import uuid from 'uuid';
import apiClient from './api-client';

import type {
  ElementType,
  PageViewData,
  SignupStepData,
  TellStevenPayload,
  UserInvitedData,
  ValidEvent,
  EntityCrudData,
  UploadAction,
  ElementClickedData,
  SettingsChangedData,
} from 'types/steven';
import {
  checkKeyExists,
  getDefaultMessage,
  type TranslationKey,
} from 'javascripts/translations/i18n';

function getCookie(cname: string) {
  const name = `${cname}=`;
  // gets all cookies in a single string
  const decodedCookie = decodeURIComponent(document.cookie);
  // splits our long string into an array of cookies
  const cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i += 1) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    // if cookie is found return the value of the cookie
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
}

function deleteCookie(cname: string) {
  document.cookie = `${cname}=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.companycam.com;  Path=/;`;
}

const getStevenSession = () => {
  const storedItem = window.localStorage.getItem('stevenId');
  const currentSession = storedItem && JSON.parse(storedItem);

  if (Date.now() > currentSession?.expiry) {
    return [currentSession, true];
  }

  return [currentSession, false];
};

// event type constants
const ELEMENT_CLICKED: ValidEvent = 'element_clicked';
const USER_INVITED: ValidEvent = 'user_invited';
const PAGE_VIEW: ValidEvent = 'page_view';
const SIGNUP_STEP: ValidEvent = 'signup_step';
const ENTITY_CRUD: ValidEvent = 'entity_crud';
const CAMERA_UPLOAD_ACTION: ValidEvent = 'camera_upload_action';
const SETTINGS_CHANGED: ValidEvent = 'settings_changed';

/**
 * element type constants
 * @deprecated - these types are no longer enforced and element type can be any string
 */
export const ELEMENT_TYPES: { [key: string]: ElementType } = {
  BUTTON: 'button',
  LINK: 'link',
  NOTIFICATION: 'notification',
  CARD: 'card',
  DATEPICKER: 'datepicker',
  DROPDOWN: 'dropdown',
};

// Looking for non-code docs? Check out the Event Logging guide (https://www.notion.so/companycam/Event-Logging-4d785a8e2aec4290b11576f18c5859c1?pvs=4)
// or the User Manual (https://www.notion.so/companycam/Steven-A-User-s-Manual-1b8cf505e2e74156a77b34d04c434f13?pvs=4)

const start = () => {
  const [currentSession, isExpired] = getStevenSession();
  let stevenDeviceIdCookie = getCookie('stevenDeviceId');
  const stevenSessionIdCookie = getCookie('stevenSessionId');
  const stevenEventIndexCookie = parseInt(getCookie('stevenEventIndex'));

  if (!stevenDeviceIdCookie) {
    const newDeviceId = uuid.v4();
    document.cookie = `stevenDeviceId=${newDeviceId}; expires=Tue, 19 Jan 2038 04:14:07 GMT; domain=.companycam.com; path=/`; // Y2038
    stevenDeviceIdCookie = newDeviceId;
  }

  if (stevenSessionIdCookie || !currentSession || isExpired) {
    const eventIndex =
      stevenSessionIdCookie && !Number.isNaN(stevenEventIndexCookie)
        ? stevenEventIndexCookie
        : 0;

    const newSession = {
      deviceId: stevenDeviceIdCookie,
      sessionId: stevenSessionIdCookie || uuid.v4(),
      expiry: Date.now() + 86400000, // tomorrow
      /**
       * While optional chaining returns undefined, localStorage (or more directly, Storage) returns null for missing keys.
       * previousSessionId must be either a UUIDv4 or null, so this implementation should be safe (and functionally match mobile Steven).
       */
      previousSessionId: currentSession?.sessionId,
      sessionIndex: (currentSession?.sessionIndex || 0) + 1,
      eventIndex,
    };

    deleteCookie('stevenSessionId');
    deleteCookie('stevenEventIndex');
    window.localStorage.setItem('stevenId', JSON.stringify(newSession));

    return newSession;
  }

  return currentSession;
};

const incrementEventIndex = () => {
  const currentSession = JSON.parse(window.localStorage.getItem('stevenId'));

  if (!currentSession) {
    const newSession = start();
    newSession.eventIndex += 1;
    return newSession;
  }

  currentSession.eventIndex += 1;
  window.localStorage.setItem('stevenId', JSON.stringify(currentSession));
  return currentSession;
};

function getCoordinates(): Promise<GeolocationPosition> {
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 600000,
  };

  return new Promise((resolve) => {
    const resolveAsReject = () => resolve({} as GeolocationPosition);
    window.navigator.geolocation.getCurrentPosition(
      resolve,
      resolveAsReject,
      options,
    );
  });
}

async function getMetadata() {
  let referrer = document.referrer;
  if (window.top) {
    referrer = window.top.document.referrer;
  } else if (window.parent) {
    referrer = window.parent.document.referrer;
  }

  const metadata = {
    deviceScreenHeight: window.innerHeight,
    deviceScreenWidth: window.innerWidth,
    dvce_created_tstamp: Date.now(),
    page_url: window.location.href,
    page_urlhost: window.location.hostname,
    page_urlpath: window.location.pathname,
    page_urlport: window.location.port,
    page_urlscheme: window.location.protocol.slice(0, -1), // drop the trailing colon
    page_referrer: referrer,
    platform: 'web',
  };

  let isGeolocationGranted = false;

  if (navigator?.permissions) {
    const { state } = await navigator.permissions.query({
      name: 'geolocation',
    });
    isGeolocationGranted = state === 'granted';
  }

  if (isGeolocationGranted) {
    const geolocation = await getCoordinates();
    const geoData = {
      geo_latitude: geolocation?.coords?.latitude,
      geo_longitude: geolocation?.coords?.longitude,
    };

    return { ...metadata, ...geoData };
  }

  return metadata;
}

async function tellSteven(name: ValidEvent, data: TellStevenPayload) {
  const sessionData = incrementEventIndex();
  const metadata = await getMetadata();
  const body = {
    tracked_event: {
      name,
      data: {
        ...metadata,
        ...sessionData,
        ...data,
      },
    },
  };

  apiClient.post('steven', body);
}

/**
 * Events you can use where needed!
 * Add new events here (and to the default export) - just remember to tellSteven about them.
 */

/**
 * Use case: tracking when the user taps/clicks on a UI element, and only that the element was tapped/clicked.
 *
 * @param {Object} eventDetails - describes the context of the triggered event
 * @param {string} eventDetails.id - unique id of the element that was clicked
 * @param {string} eventDetails.content - Translation key for the copy of the element that was clicked. You can pass an English string if required but this will throw a warning.
 * @param {string} eventDetails.type - tagName of the element that was clicked
 * @param {string} eventDetails.action - name of the action being called
 * @param {string} eventDetails.entity_id - database id of the entity being clicked
 */
function elementClicked({
  id,
  content,
  type,
  action,
  entity_id,
}: ElementClickedData) {
  let contentString = content;
  if (checkKeyExists(content)) {
    contentString = getDefaultMessage(content as TranslationKey);
  } else {
    console.warn(
      `ElementClicked content for ${id} was not a valid translation key. Please ensure this was intentional.`,
    );
  }

  tellSteven(ELEMENT_CLICKED, {
    id,
    content: contentString,
    type,
    action,
    entity_id,
  });
}

/**
 * Use case: tracking when a user has successfully sent an invitation to an invitee.
 *
 * @param {string 128} invitee_id - invitee ID
 * @param {('manage_users'|'mentions'|'dash'|'api'|'contacts')} source - source of the invitation action
 */
function userInvited({ invitee_id, source }: UserInvitedData) {
  tellSteven(USER_INVITED, {
    invitee_id,
    source,
  });
}

/**
 * Use case: tracking where the user ends up after a page has loaded.
 * If you aren't modifying the ccEventsOnPageLoad integration, you probably want a different event.
 *
 * @param {string} pathname - path of the page the user was routed to
 * @param {string} url - full URL of the page the user was routed to
 */
function pageView({ pathname, url }: PageViewData) {
  tellSteven(PAGE_VIEW, { pathname, url });
}

/**
 * Use case: tracking that the user reaching, but not necessarily getting past, any and all steps in the signup flow.
 *
 * @param {string 255} step_name - a step of the sign-up process
 */
function signupStep({ step_name }: SignupStepData) {
  tellSteven(SIGNUP_STEP, { step_name });
}
/**
 * Use case: Tracking when an upload has completed. This event mirrors the mobile event of the same shape.
 * it's important that the camera events are consistent across platforms.
 *
 * @param {'photo'|'video'} captured_asset_type - string representing the type of asset: photo | video
 * @param {string} captured_asset_id - string representing the DB id of the asset captured.
 * @param {number | null} duration_seconds - number (or null) representing the asset duration, if applicable
 * @param {string} upload_type - string representing the method of upload: standard or multipart.
 */

export function uploadAction({
  captured_asset_id,
  captured_asset_type,
  duration_seconds = null,
  upload_type = 'standard',
}: UploadAction) {
  tellSteven(CAMERA_UPLOAD_ACTION, {
    camera_option_settings: null,
    camera_session_id: null,
    captured_asset_id,
    captured_asset_type,
    captured_asset_uuid: null,
    duration_seconds,
    orientation: null,
    resolution: null,
    source: 'external',
    upload_type,
  });
}

/**
 * Use case: tracking when a user creates, updates, or deletes an entity.
 * @param {string} entity_type - string representing the type of entity being created, updated, or deleted - should correspond to a database model
 * @param {string} entity_id - database id of the entity being created, updated, or deleted
 * @param {string} action - type of action being performed on the entity (create, update, delete)
 * @param {string | null} source - optional, location where the event was triggered from. For example 'template'
 * @param {string | null} updated_action - optional, a more granular description of the update. For example 'name_changed' or 'status_changed'
 */
function entityCrud({
  entity_type,
  entity_id,
  action,
  source,
  updated_action,
}: EntityCrudData) {
  tellSteven(ENTITY_CRUD, {
    entity_type,
    entity_id,
    action,
    source,
    updated_action,
  });
}

/**
 * Use case: tracking when a user changes a setting
 *
 * @param {string} setting_type - the overall type of settings being changed
 * @param {string} setting_name - the specific setting name. (e.g. enhanced_gps in Location Settings)
 * @param {string} starting_value - the setting value before the event fired
 * @param {string} ending_value - the setting value after the event fired
 */
function settingsChanged({
  setting_type,
  setting_name,
  starting_value,
  ending_value,
}: SettingsChangedData) {
  tellSteven(SETTINGS_CHANGED, {
    setting_type,
    // Steven expects snake_case for setting names
    setting_name: setting_name
      .replace(/([a-z0-9])([A-Z])/g, '$1_$2')
      .toLowerCase(),
    starting_value,
    ending_value,
  });
}

export const Steven = {
  elementClicked,
  entityCrud,
  pageView,
  signupStep,
  start,
  uploadAction,
  userInvited,
  settingsChanged,
};
